import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import "../assets/mlingua.css"

const MultiLingua = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "preporuka" })}
        canonicalUrl={"recomendation-multilingua"}
      />
      <div className="mlingua">
        <StaticImage
          className="mlingua-img"
          src="../images/recomend/MULTILINQUA_RECOMMONDATION.jpg"
          alt="Multilingua recomendation"
          layout="constrained"
          placeholder="blurred"
        />
      </div>
    </Layout>
  )
}

export default MultiLingua
